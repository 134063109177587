import { Controller } from "@hotwired/stimulus";
import { Fancybox } from "@fancyapps/ui";


export default class extends Controller {
    connect() {
        this.layout();
    }

    layout() {
        let targetRowHeight = 150;

        if (window.innerWidth > 500) {
            targetRowHeight = 250;
        }

        let width = this.element.offsetWidth
        let layoutGeometry = require('justified-layout')(this.buildPhotosObjectFromPage(this.element), {
            'containerWidth': width,
            'containerPadding': 0,
            'targetRowHeight': targetRowHeight,
            'boxSpacing': 15
        })

        // Sets height of gallery wrapper element
        this.element.style.height = layoutGeometry.containerHeight + 'px'

        // Absolutely positions each image within the gallery wrapper according to Justified Layout calculations
        layoutGeometry.boxes.map((box, i) => {
            let num = i + 1

            let item = this.element.querySelector('li:nth-of-type(' + num + ')')
            // figure.setAttribute('data-index', i)
            item.style.width = box.width + 'px'
            item.style.height = box.height + 'px'
            item.style.top = box.top + 'px'
            item.style.left = box.left + 'px'
        })
    }

    buildPhotosObjectFromPage(gallery) {
        let thumbElements = gallery.children
        let items = []
        let figureEl
        // let linkEl
        let item

        // Loop over all photos
        for (let i = 0; i < thumbElements.length; i++) {
            figureEl = thumbElements[i] // <li>
            // if (figureEl.querySelector('a')) {
            //     linkEl = figureEl.querySelector('a') // <a>
            // }

            // Build slide object
            item = {
                id: figureEl.getAttribute('data-index'),
                width: parseInt(figureEl.getAttribute('data-width'), 10),  // Used for justified-layout
                height: parseInt(figureEl.getAttribute('data-height'), 10),  // Used for justified-layout
            }

            items.push(item)
        }

        return items;
    }
}
 